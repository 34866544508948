enum InterviewType {
  technicalExercise = 'technical exercise',
  takehome = 'take-home',
  pairingProject = 'pairing project',
  whiteboard = 'whiteboarding',
  roundTable = 'round-table',
  presentation = 'presentation',
  critique = 'critique',
  inperson = 'in-person',
  remote = 'remote',
}

export default InterviewType;