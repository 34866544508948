import { Box, FormLabel } from "@mui/material";
import { useContext, useState } from "react";
import { StateContext, DispatchContext } from "../../utils/providers";
import updateJob from "../../utils/updateJob";
import RichEditor from "../Input/RichEditor";
import 'draft-js/dist/Draft.css';

const WizardPolicies = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const deiPolicyChange = (content: object) => {
    const deiPolicy = JSON.stringify(content);
    let job = state.createJob.job;
    job.deiPolicy = deiPolicy;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const whyThisCompany = (content: object) => {
    const whyThisCompany = JSON.stringify(content);
    let job = state.createJob.job;
    job.whyThisCompany = whyThisCompany;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  return (
    <Box 
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      autoComplete="off">
        <FormLabel style={{marginLeft: '8px', marginRight: '8px'}}>DEI Policy</FormLabel>
        <RichEditor
          className="jd-tldr"
          placeHolder="Enter your DEI Policy"
          onEditorChange={deiPolicyChange}
          initialContent={state.createJob?.job?.deiPolicy ? state.createJob.job.deiPolicy : null}
          ></RichEditor>
        <FormLabel style={{marginLeft: '8px', marginRight: '8px'}}>Why Choose this Company?</FormLabel>
        <RichEditor
          className="jd-tldr"
          placeHolder="Why Choose This Company?"
          onEditorChange={whyThisCompany}
          initialContent={state.createJob?.job?.whyThisCompany ? state.createJob.job.whyThisCompany : null}
          ></RichEditor>
    </Box>
  );
};

export default WizardPolicies;