import { Box, Button, FormLabel, TextField, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { StateContext, DispatchContext } from "../../utils/providers";
import updateJob from "../../utils/updateJob";
import RichEditor from "../Input/RichEditor";
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from "firebase/functions";
import FirebaseApp from '../../utils/firebaseApp';

import 'draft-js/dist/Draft.css';

const WizardDescription = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [jobDescriptionUrl, setJobDescriptionUrl] = useState('');
  const [jdLinking, setJdLinking] = useState(false);
  const auth = getAuth(FirebaseApp);

  const jobDescriptionChange = (content: object) => {
    const jobDescription = JSON.stringify(content);
    let job = state.createJob.job;
    if (!job) {
      return;
    } else {
      job.jobDescriptionTldr = jobDescription;
    }
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const jobDescriptionUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const jdUrl = event.target.value;
    let job = state.createJob.job;
    job.jobDescriptionUrl = jdUrl;
    setJobDescriptionUrl(jdUrl);
  };

  const jobDescriptionLinkChange = () => {
    let job = state.createJob.job;
    if (jobDescriptionUrl && jobDescriptionUrl.length > 3 && jobDescriptionUrl.indexOf('.') > 0) {
      setJdLinking(true);
      const functions = getFunctions(FirebaseApp);
      const fetchMetadataScreenshot = httpsCallable(functions, 'fetchMetadataScreenshot');
      fetchMetadataScreenshot({
        url: jobDescriptionUrl,
        uid: auth.currentUser?.uid,
        jobId: job.jobId
      })
      .then((metadata) => {
        if (metadata.data && metadata.data.error) {
          console.log(metadata.data.error);
          setJdLinking(false);
        }
        else if (job) {
          job.jobDescriptionTitle = metadata.data.title;
          job.jobDescriptionMetaDesc = metadata.data.description;
          job.jdImageUrl = metadata.data.imageUrl;
          setJdLinking(false);
          dispatch({ type: 'UPDATE_JOB', job: job});
          updateJob(job);
        }
      });
    }
  };
  const applyLinkChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const applyLink = event.target.value;
    let job = state.createJob.job;
    job.applyLink = applyLink;
    if (applyLink && applyLink.length > 3 && applyLink.indexOf('.') > 0) {      
      dispatch({ type: 'UPDATE_JOB', job: job});
      updateJob(job);
    }
  };
  return (
    <Box 
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
      autoComplete="off">
      <FormLabel>Instead of Copy/Pasting Your Job Description / Req. Simply link to it and we'll put a screenshot in the post</FormLabel>
      <TextField
        fullWidth={true}
        id="outlined-basic"
        label="Job Description Link"
        variant="outlined"
        value={state.createJob.job.jobDescriptionUrl}
        onChange={jobDescriptionUrlChange}
        style={{marginLeft: '8px', marginRight: '8px'}}
        />
      <Button
        variant="contained"
        style={{marginLeft: '8px', marginRight: '8px'}}
        disabled={jdLinking}
        onClick={jobDescriptionLinkChange}>Link Job Description</Button>
      <aside>
        {jdLinking && (
          <span>
            Linking Job Description from {state.createJob.job.jobDescriptionUrl}:
            <CircularProgress variant="indeterminate" />
          </span>
        )}
      </aside>
      <TextField
          fullWidth={true}
          id="outlined-basic"
          label="Application Link"
          variant="outlined"
          onChange={applyLinkChanged}
          value={state.createJob.job.applyLink}
          style={{marginLeft: '8px', marginRight: '8px'}}
          />
      <RichEditor
        className="jd-tldr"
        placeHolder="Enter a job description summary (JD TLDR)"
        onEditorChange={jobDescriptionChange}
        initialContent={state.createJob?.job?.jobDescriptionTldr ? state.createJob.job.jobDescriptionTldr : null}
        ></RichEditor>
    </Box>
  )
};

export default WizardDescription;