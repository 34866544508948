enum JobType {
  product = "Product",
  design = "Design",
  development = "Development",
  support = "Support",
  qa = "QA",
  it = "IT",
  data = "Data"
}

export default JobType;