import { StateContext, DispatchContext } from "../../utils/providers";
import React, { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import InterviewType from '../../Entities/InterviewType';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import updateJob from '../../utils/updateJob';
import RichEditor from "../Input/RichEditor";

const WizardInterview = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const interviewProcessChange = (content: object) => {
    const interviewProcess = JSON.stringify(content);
    let job = state.createJob.job;
    job.interviewProcess = interviewProcess;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const teamExplanationChange = (content: object) => {
    const teamExplanation = JSON.stringify(content);
    let job = state.createJob.job;
    job.teamExplanation = teamExplanation;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const interviewLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const interviewLength = event.target.value;
    let job = state.createJob.job;
    job.interviewProcessLength = interviewLength;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const interviewTypeChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const interviewTypeChecked = event.target.checked;
    let job = state.createJob.job;
    if (job.interviewAttributes && job.interviewAttributes.length > 0) {
      if (interviewTypeChecked) {
        job.interviewAttributes.push(type);
      } else {
        const index = job.interviewAttributes.indexOf(type);
        if (index > -1) {
          job.interviewAttributes.splice(index, 1);
        }
      }
    } else {
      if (interviewTypeChecked) {
        job.interviewAttributes = [type];
      }
    }
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const interviewTypes = Object.values(InterviewType);

  const interviewTypeControl = interviewTypes.map(type => {
    let job = state.createJob.job;
    let checked = false;
    job.interviewAttributes?.forEach(attrib => {
      if (attrib === type) {
        checked = true;
      }
    });
    return (
      <FormControlLabel key={type} control={<Checkbox checked={checked} onChange={e => interviewTypeChange(e, type)} />} label={type} />
    )
  });
  
  return (
    <Box 
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
      autoComplete="off">
      <FormLabel style={{marginLeft: '8px', marginRight: '8px'}}>Interview Process Standards</FormLabel>
      <FormGroup style={{flexDirection: 'row', marginLeft: '8px', marginRight: '8px'}}>
        {interviewTypeControl}
      </FormGroup>
      <RichEditor
        className="interview-process"
        placeHolder="Enter your standard interview process"
        onEditorChange={interviewProcessChange}
        initialContent={state.createJob?.job?.interviewProcess ? state.createJob.job.interviewProcess : null}
        ></RichEditor>
      <TextField
        id="outlined-basic-process"
        label="Interview Process Typical Length"
        onChange={interviewLengthChange}
        variant="outlined"
        placeholder="ie. 3 Weeks"
        value={state.createJob.job.interviewProcessLength}
        fullWidth={true}
        required />
      <RichEditor
        className="interview-process"
        placeHolder="Enter any good to know team specific details"
        onEditorChange={teamExplanationChange}
        initialContent={state.createJob?.job?.teamExplanation ? state.createJob.job.teamExplanation : null}
        ></RichEditor>
    </Box>
  )
};

export default WizardInterview;