import { StateContext, DispatchContext } from "../../utils/providers";
import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import updateJob from '../../utils/updateJob';
import RichEditor from "../Input/RichEditor";
import Benefits from "../../Entities/Benefits";
import jobHits from "../Jobs/jobHits";

const WizardVariable = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const benefitDetailsChange = (content: object) => {
    const benefitDetails = JSON.stringify(content);
    let job = state.createJob.job;
    job.benefitsSummary = benefitDetails;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const benefitChange = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const benefitChecked = event.target.checked;
    let job = state.createJob.job;
    if (job.benefits && job.benefits.length > 0) {
      if (benefitChecked) {
        job.benefits.push(type);
      } else {
        const index = job.benefits.indexOf(type);
        if (index > -1) {
          job.benefits.splice(index, 1);
        }
      }
    } else {
      if (benefitChecked) {
        job.benefits = [type];
      }
    }
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const benefits = Object.values(Benefits);

  const benefitsControl = benefits.map(type => {
    let job = state.createJob.job;
    let checked = false;
    job.benefits?.forEach(benefit => {
      if (benefit === type) {
        checked = true;
      }
    });
    return (
      <FormControlLabel key={type} control={<Checkbox checked={checked} onChange={e => benefitChange(e, type)} />} label={type} />
    )
  });
  
  return (
    <Box 
      component="form"
      autoComplete="off">
      <FormLabel style={{marginLeft: '8px', marginRight: '8px'}}>Benefits List</FormLabel>
      <FormGroup style={{flexDirection: 'row', marginLeft: '8px', marginRight: '8px'}}>
        {benefitsControl}
      </FormGroup>
      <FormLabel style={{marginLeft: '8px', marginRight: '8px'}}>Benefits Summary</FormLabel>
      <RichEditor
        className="interview-process"
        placeHolder="Benefits Summary"
        onEditorChange={benefitDetailsChange}
        initialContent={state.createJob?.job?.benefitsSummary ? state.createJob.job.benefitsSummary : null}
        ></RichEditor>
    </Box>
  )
};

export default WizardVariable;