import { useEffect, useState } from 'react';
import UserImage from "../../Entities/UserImage";
import GetUserImages from '../../utils/getUserImages';
import { RadioGroup, Radio, FormControlLabel } from '@mui/material';

export type WizardLogoSelectProps = {
  logoImageUrl: string,
  onSelectLogo: Function,
  refreshFiles: boolean
};

const WizardLogoSelect = ({logoImageUrl, onSelectLogo, refreshFiles}: WizardLogoSelectProps) => {
  const [logoList, setLogoList] = useState<UserImage[] | null>(null);

  useEffect(() => {
    GetUserImages().then((imageList) => {
      setLogoList(imageList);
    });
  }, [refreshFiles]);

  const onLogoSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelectLogo((event.target as HTMLInputElement).value);
  };

  return (
    <>
      {logoList && logoList.length > 0 && (
        <div className="wizard-user-images__title">
          Select from your existing images
        </div>
      )}
      <RadioGroup aria-label="logo" name="radio-buttons-group" value={logoImageUrl} onChange={onLogoSelectChange}>
        <div className="wizard-user-images">
          {logoList && logoList.map((logo, index) => {
            return (
              <div className="wizard-user-images__card" key={`ui-card${index}`}>
                <img src={logo.imageUrl} alt={`user logo ${index}`} />
                <span className="wizard-user-images__card-select">
                  <FormControlLabel value={logo.imageUrl} control={<Radio />} label="select" />
                </span>
              </div>
          )})}
        </div>
      </RadioGroup>
    </>
  )
};

export default WizardLogoSelect;