import {Editor, EditorState, RichUtils, getDefaultKeyBinding, convertFromRaw, convertToRaw } from 'draft-js';
import { useState, createRef } from 'react';
import debounce from '@mui/utils/debounce';

import './RichEditor.scss';

export type RichEditorProps = {
  className?: string,
  placeHolder?: string,
  onEditorChange?: Function,
  initialContent?: string
};

const RichEditor = ({className, placeHolder, onEditorChange, initialContent}: RichEditorProps) => {
  let initialState = null;
  if (initialContent) {
    const convertedState = convertFromRaw(JSON.parse(initialContent));
    const editorState = EditorState.createWithContent(convertedState);

    initialState = editorState;
  } else {
    initialState = EditorState.createEmpty();
  }
  
  const [editorState, setEditorState] = useState(
    initialState,
  );

  const [isFocused, setIsFocused] = useState(false);

  const textInput = createRef();

  const setFocus = () => {
    textInput.current.focus();
  }

  const onFocus = () => {
    setIsFocused(true);
  }

  const onBlur = () => {
    setIsFocused(false);
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  };

  const mapKeyToEditorCommand = (e) => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  const toggleBlockType = (blockType) => {
    setEditorState(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    );
  }

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  }

  const handleValueChange = debounce((content) => {
    if (onEditorChange)
      onEditorChange(content);
  }, 400);

  const handleOnChange = (e) => {
    setEditorState(e);    
    if (onEditorChange && e) {
      try {
        const currentContent = e.getCurrentContent();
        const rawValue = convertToRaw(currentContent);
        handleValueChange(rawValue);
      }
      catch (e) {
        
      }
    }
  }

  return (
    <div className={`rich-editor ${isFocused ? 'rich-editor--focused' : ''}`}>
      <BlockStyleControls
        editorState={editorState}
        onToggle={toggleBlockType}
      />
      <InlineStyleControls
        editorState={editorState}
        onToggle={toggleInlineStyle}
      />
      <div className={className} onClick={setFocus}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={handleOnChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeHolder}
          ref={textInput}
          spellCheck={true}
        />
      </div>
    </div>
  )
};

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote': return 'rich-editor__blockquote';
    default: return null;
  }
}

type StyleButtonProps = {
  style: string,
  label: string,
  active: boolean,
  onToggle: Function
}

const StyleButton = ({style, label, active, onToggle}:StyleButtonProps) => {
  let className = 'rich-editor__style-button';
  if (active) {
    className += ' rich-editor__active-button';
  }

  const toggleButton = (e) => {
    e.preventDefault();
    onToggle(style);
  }

  return (
    <span className={className} onMouseDown={toggleButton}>
      {label}
    </span>
  );
}

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  {label: 'Blockquote', style: 'blockquote'},
  {label: 'UL', style: 'unordered-list-item'},
  {label: 'OL', style: 'ordered-list-item'},
  {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = ({editorState, onToggle}) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="rich-editor__controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD'},
  {label: 'Italic', style: 'ITALIC'},
  {label: 'Underline', style: 'UNDERLINE'},
  {label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = ({editorState, onToggle}) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  
  return (
    <div className="rich-editor__controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};



export default RichEditor;