import { FormLabel, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { StateContext, DispatchContext } from "../../utils/providers";
import { useContext, use } from 'react';
import updateJob from '../../utils/updateJob';
import Button from "@mui/material/Button";

import './wizardActions.scss';

export type WizardActionsProps = {
  previousTab?: any,
  nextTab?: any
}

const WizardActions = ({previousTab, nextTab}: WizardActionsProps) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const changePromoted = (event: React.ChangeEvent<HTMLInputElement>) => {
    const promotedChecked = event.target.checked;
    let job = state.createJob.job;
    job.promoted = promotedChecked;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  }

  const addJobToCart = () => {
    dispatch({ type: 'ADD_JOB_TO_CART', job: job});
  };

  const removeJobFromCart = () => {
    dispatch({ type: 'REMOVE_JOB_FROM_CART', jobId: job.jobId});
  };

  const job = state.createJob.job;
  const jobInCart = state.job.jobsInCart?.find((job) => { return job.jobId === job.jobId });
  const currentDate = new Date();
  const jobActive = job.createDate && job.createDate.toDate() < currentDate;
  const jobExpired = job.expirationDate && job.expirationDate.toDate() < currentDate;
  const hasPayRange = job.payRangeStart !== undefined && job.payRangeEnd !== undefined;
  const payRangeGreaterThanZero = job.payRangeStart && job.payRangeStart !== 0 && job.payRangeEnd !== 0;
  const atLeast50CharsDEIPolicy = job.deiPolicy !== undefined && job.deiPolicy.length > 50;
  const hasTitle = job.jobTitle !== undefined && job.jobTitle.length > 2;
  const hasCompany = job.company !== undefined && job.company.length > 2;
  const hasApplicationLink = job.applyLink !== undefined && job.applyLink.length > 4;
  const complete = hasPayRange && payRangeGreaterThanZero && atLeast50CharsDEIPolicy && hasTitle &&
    hasCompany && hasApplicationLink;

  return (
    <div className="wizard-actions">
      {!jobActive && !jobExpired && (
        <FormControlLabel 
          control={<Checkbox checked={state.createJob.job.promoted} onChange={changePromoted} />} 
          label="Promote Job? ($50)" />
      )}
      {previousTab && 
        <Button
          variant="outlined"
          onClick={previousTab}>Previous</Button>
      }
      {nextTab &&
        <Button
          variant="outlined"
          onClick={nextTab}>Next</Button>
      }
      {!complete && !jobActive && !jobExpired && !jobInCart && (
        "Complete TODO Items to Add to Cart"
      )}
      {complete && !jobActive && !jobExpired && !jobInCart && (
        <Button
          variant="contained" onClick={addJobToCart}>Done? Add to cart</Button>
      )}
      {complete && !jobActive && !jobExpired && jobInCart && (
        <Button
          variant="contained" onClick={removeJobFromCart}>Remove from cart</Button>
      )}
    </div>
  )
};

export default WizardActions;