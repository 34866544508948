import { StateContext, DispatchContext } from "../../utils/providers";
import { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import updateJob from '../../utils/updateJob';

const WizardTitle = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const [payRangeStartError, setPayRangeStartError] = useState("");
  const [payRangeEndError, setPayRangeEndError] = useState("");

  const payStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!validateNumeric(event)) {
      setPayRangeStartError("Please Input a Number");
    } else {
      setPayRangeStartError("");
    }
    const payStart = event.target.value;
    let job = state.createJob.job;
    job.payRangeStart = Number.parseInt(payStart);
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const payEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!validateNumeric(event)) {
      setPayRangeEndError("Please Input a Number");
    } else {
      setPayRangeEndError("");
    }
    const payEnd = event.target.value;
    let job = state.createJob.job;
    job.payRangeEnd = Number.parseInt(payEnd);
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  const validateNumeric = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isNumber = Number(event.target.value);
    return !isNaN(isNumber);
  };
  
  return (
    <Box 
      component="form"
      noValidate
      autoComplete="off">
      <TextField
        id="outlined-basic"
        label="Pay Range Start"
        onChange={payStartChange}
        variant="outlined"
        inputMode="numeric"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">k</InputAdornment>
        }}
        required
        error={payRangeStartError !== "" ? true : false}
        value={state.createJob.job.payRangeStart}
        helperText={payRangeStartError} />
      <TextField
        id="outlined-basic"
        label="Pay Range End"
        onChange={payEndChange}
        variant="outlined"
        inputMode="numeric"
        fullWidth={true}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">k</InputAdornment>
        }}
        required
        value={state.createJob.job.payRangeEnd}
        error={payRangeEndError !== "" ? true : false}
        helperText={payRangeEndError} />
    </Box>
  )
};

export default WizardTitle;