import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import { useContext } from "react";
import { StateContext, DispatchContext } from "../../utils/providers";
import MenuItem from "@mui/material/MenuItem";
import JobType from "../../Entities/JobType";
import updateJob from "../../utils/updateJob";
import InputLabel from '@mui/material/InputLabel';

const WizardType = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const jobTypeChange = (event: SelectChangeEvent) => {
    const jobType = event.target.value;
    let job = state.createJob.job;
    job.jobType = jobType;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  }
  return (
    <FormControl fullWidth={true}>
      <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
      <Select
        value={state.createJob.job.jobType || ''}
        label="Job Type"
        onChange={jobTypeChange}>
          {Object.values(JobType).map(type => {
            return (
              <MenuItem value={type} key={type}>{type.toString()}</MenuItem>
            )
          })}
      </Select>
    </FormControl>
  )
};

export default WizardType;