import { useState } from 'react';
import JobPostSmall from '../Jobs/jobPostSmall';
import { StateContext, DispatchContext } from "../../utils/providers";
import { useContext } from 'react';
import WizardTitle from './wizardTitle';
import WizardPayRange from './wizardPayRange';
import WizardVariable from './wizardBenefits';
import WizardInterview from './wizardInterview';
import WizardType from './wizardType';
import WizardDescription from './wizardDescription';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import JobDetailsView from '../Jobs/jobDetailsView';
import WizardLogo from './wizardLogo';
import WizardPolicies from './wizardPolicies';
import WizardActions from './wizardActions';
import WizardTodo from './wizardTodo';

import './wizardLayout.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

const a11yProps = (index: any) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const WizardLayout = () => {
  const state = useContext(StateContext);
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  const previousTab = () => {
    setValue(value - 1);
  }

  const nextTab = () => {
    setValue(value + 1);
  }

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <>
      <Card className="wizard-layout__card">
        <div className="wizard-layout">
          <div className="wizard-layout__form">
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="action tabs example"
              >
                <Tab wrapped label="Job Title" {...a11yProps(0)} />
                <Tab wrapped label="Logo" {...a11yProps(1)} />
                <Tab wrapped label="Policies" {...a11yProps(2)} />
                <Tab wrapped label="Pay & Benefits" {...a11yProps(3)} />
                <Tab wrapped label="Interview Process" {...a11yProps(4)} />
                <Tab wrapped label="Job Description" {...a11yProps(5)} />                
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <WizardTitle />
                <WizardType />
                <WizardActions nextTab={nextTab} />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <WizardLogo />
                <WizardActions previousTab={previousTab} nextTab={nextTab} />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <WizardPolicies />
                <WizardActions previousTab={previousTab} nextTab={nextTab} />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <WizardPayRange />
                <WizardVariable />
                <WizardActions previousTab={previousTab} nextTab={nextTab} />
              </TabPanel>
              <TabPanel value={value} index={4} dir={theme.direction}>
                <WizardInterview />
                <WizardActions previousTab={previousTab} nextTab={nextTab} />
              </TabPanel>
              <TabPanel value={value} index={5} dir={theme.direction}>
                <WizardDescription />
                <WizardActions previousTab={previousTab} />
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
      </Card>
      <Card className="wizard-layout__card wizard-layout__card-padding">
        <WizardTodo />
      </Card>
      <Card className="wizard-layout__card wizard-layout__card-padding">
        <div className="wizard-user-images__title">
          Preview - Homepage and Search Results
        </div>
        <div className="wizard-layout__card-small">
          <JobPostSmall job={state.createJob.job} featured={state.createJob.featured ? state.createJob.featured : false} />
        </div>
      </Card>
      <Card className="wizard-layout__card wizard-layout__card-padding">
        <div className="wizard-user-images__title">
          Preview - Job Post
        </div>
        <div className="wizard-layout__card-small">
          <JobDetailsView job={state.createJob.job} />
        </div>
      </Card>
    </>
  );
};

export default WizardLayout;