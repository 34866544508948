import Job from "../Entities/Job";
import { getAuth } from "firebase/auth";
import firebaseApp from './firebaseApp';
import { doc, setDoc, getFirestore } from "firebase/firestore";
import debounce from '@mui/utils/debounce';

const updateJob = debounce((job: Job) => {
  const auth = getAuth(firebaseApp);
  const db = getFirestore(firebaseApp);
  const user = auth.currentUser;
  if (user) {
    job.userId = user.uid;
    setDoc(doc(db, 'jobs', job.jobId), job);
  }
}, 400);

export default updateJob;