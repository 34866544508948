import {useDropzone, FileWithPath} from 'react-dropzone';
import UploadImage from '../../utils/uploadImage';
import { CircularProgress } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { StateContext, DispatchContext } from "../../utils/providers";
import WizardLogoSelect from './wizardLogoSelect';
import updateJob from '../../utils/updateJob';

const WizardLogo = () => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1
  });
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null);
  const [refreshFiles, setRefreshFiles] = useState(false);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const job = state.createJob.job;

  const selectLogo = (url: string) => {
    job.logoImageUrl = url;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
  };

  useEffect(() => {
    const selectedFile = acceptedFiles.length > 0 ? acceptedFiles[0] : null;
    if (selectedFile) {
      setRefreshFiles(false);
      setAcceptedFile(selectedFile);
      UploadImage(selectedFile.type, selectedFile, "logo", dispatch, state.createJob.job);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (state.job.uploadPercentage >= 90) {
      setRefreshFiles(true);
    }
  }, [state.job.uploadPercentage]);

  return (
    <>
      <WizardLogoSelect
        refreshFiles={refreshFiles}
        logoImageUrl={job.logoImageUrl ? job.logoImageUrl : ''}
        onSelectLogo={selectLogo} />
      <div className="wizard-user-images__title">
        Upload a new logo
      </div>
      <div {...getRootProps({ className: 'wizard__dropzone' })}>
        <input {...getInputProps()} />
        <p>Logo *</p>
        <em>Click or Drag and Drop Logo</em>
      </div>
      <aside>
        {state.job && state.job.uploadInProgress && (
          <span>
            Uploading {acceptedFile && acceptedFile.name}: <CircularProgress variant="determinate" value={state.job.uploadPercentage} />
          </span>
        )}
      </aside>
    </>
  )
}

export default WizardLogo;