import FirebaseApp from "./firebaseApp";
import { getAuth } from "firebase/auth";
import { query, where, getDocs, collection, getFirestore } from "firebase/firestore";
import UserImage from "../Entities/UserImage";

const GetUserImages = async () => {
  const auth = getAuth(FirebaseApp);
  const db = getFirestore(FirebaseApp);
  const user = auth.currentUser;
  const userImageList: Array<UserImage> = [];

  const userImageCollection = collection(db, 'userImages');
  if (user?.uid) {
    const q = query(userImageCollection, where("userId", "==", user.uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const userImageData = doc.data();
      const userImage:UserImage = { 
        imageType: userImageData.imageType,
        imageUrl: userImageData.imageUrl,
        userId: userImageData.userId
      };
      userImageList.push(userImage);
    });
  }
  return userImageList;
};

export default GetUserImages;