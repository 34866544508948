import { useContext } from 'react';
import { StateContext } from "../../utils/providers";
import { CheckListIcon, CompletedSolidIcon, InfoSolidIcon, AlertSolidIcon } from '@fluentui/react-icons-mdl2';

const WizardTodo = () => {
  const state = useContext(StateContext);
  const job = state.createJob?.job;
  const hasPayRange = job.payRangeStart !== undefined && job.payRangeEnd !== undefined;
  const payRangeGreaterThanZero = job.payRangeStart && job.payRangeStart !== 0 && job.payRangeEnd !== 0;
  const atLeast50CharsDEIPolicy = job.deiPolicy !== undefined && job.deiPolicy.length > 50;
  const hasTitle = job.jobTitle !== undefined && job.jobTitle.length > 2;
  const hasCompany = job.company !== undefined && job.company.length > 2;
  const hasApplicationLink = job.applyLink !== undefined && job.applyLink.length > 4;
  const complete = hasPayRange && payRangeGreaterThanZero && atLeast50CharsDEIPolicy && hasTitle &&
    hasCompany && hasApplicationLink;
  let statusClass = "wizard-todo__status";
  if (complete) {
    statusClass += "--complete";
  } else {
    statusClass += "--incomplete";
  }
  return (    
    <div className="wizard-todo">
      <div className="wizard-user-images__title">
        TODO List <span className={statusClass}><InfoSolidIcon /> Status - {complete && ("Complete")} {!complete && ("Incomplete")}</span>
      </div>
      <div className="wizard-todo__subtitle">
        <CheckListIcon /> Below Must Be Completed Before Can Add This Job to Cart
      </div>
      <div className="wizard-todo__description">
        
      </div>
      <div className="wizard-todo__list">
      <ul>
          <li>
            {hasPayRange && (<CompletedSolidIcon />)}
            {!hasPayRange && (<AlertSolidIcon />)}
            &nbsp;Post Has Pay Range
          </li>
          <li>
            {payRangeGreaterThanZero && (<CompletedSolidIcon />)}
            {!payRangeGreaterThanZero && (<AlertSolidIcon />)}
            &nbsp;Pay Range Greater Than Zero
          </li>
          <li>
            {atLeast50CharsDEIPolicy && (<CompletedSolidIcon />)}
            {!atLeast50CharsDEIPolicy && (<AlertSolidIcon />)}
            &nbsp;Has DEI Policy and Policy has at least 50 characters
          </li>
          <li>
            {hasTitle && (<CompletedSolidIcon />)}
            {!hasTitle && (<AlertSolidIcon />)}
            &nbsp;Has Title
          </li>
          <li>
            {hasCompany && (<CompletedSolidIcon />)}
            {!hasCompany && (<AlertSolidIcon />)}
            &nbsp;Has Company
          </li>
          <li>
            {hasApplicationLink && (<CompletedSolidIcon />)}
            {!hasApplicationLink && (<AlertSolidIcon />)}
            &nbsp;Has Link to Apply To the Position
          </li>
        </ul>
      </div>
    </div>
  )
};

export default WizardTodo;