enum Benefits {
  healthInsurance = 'health insurance',
  paidTimeOff = 'paid time off',
  retirementSavings = 'retirement savings',
  dentalAndVision = 'dental and vision',
  extraPerks = 'extra perks',
  volunteerTimeOff = 'volunteer time off',
  familyPaidLeave = 'family paid leave',
  subsidizedTravel = 'subsidized travel',
  personalDevelopment = 'continuous learning',
  paidHigherEducation = 'paid continuing higher education',
  homeOfficeStipend = 'home office stipend',
}

export default Benefits;