import { useState } from 'react';
import Layout from "../components/layout";
import WizardLayout from "../components/wizard/wizardLayout";
import SvgText, { TextBackground } from "../components/SvgText/svgText";
import HomeScroll from "../components/homeScroll";
import firebaseApp from '../utils/firebaseApp';
import Login from '../components/login';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import '../components/post.scss';

const Post = () => {
  const auth = getAuth(firebaseApp);
  const [user] = useAuthState(auth);

  if(!user) {
    return <PostViewNonUser />;
  }

  return (
    <Layout description="Post a new Job!" pageTitle="Remote Inclusive: Job Post">  
      <div className="search-header" style={{height: '70px', fontSize: '60px', fontWeight: 700}}>
          <SvgText textBackground={TextBackground.AbstractBlue} id="1">
            <tspan x="0" dy="1em">Post a Job</tspan>
          </SvgText>
      </div>
      <div className="search-header">Show your candidates you mean business!</div>
      <WizardLayout />
      <div className="page-layout__background">
        <div className="page-layout__bg-magenta" />
        <div className="page-layout__bg-blue" />
        <div className="page-layout__bg-purple" />
      </div>
    </Layout>
  )
}

const PostViewNonUser = () => {
  const [open, setOpen] = useState(false);

  const closeDialog = () => {
    setOpen(false);
  }

  const openDialog = () => {
    setOpen(true);
  } 
  return (
    <Layout description="RemoteInclusive - Inclusive and Transparent Job Listings - Post a new Job!" pageTitle="Remote Inclusive: Job Post">
      <div className="post-header">
        <div className="post-header__layout">
          <span className="post-header__header-title">Show Your Candidates<br />You - Mean - Business!</span>
          <span className="post-header__header-subtitle">Post a Job Quickly. Save time by linking the full job description. $150 standard post. $200 Always on the Homepage Promoted Posts!</span>
          <button className="pricing-card__button" onClick={openDialog}>Post Job</button>
        </div>
        <div className="post-video">
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/GL5RCqXr6ds?cc_load_policy=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <HomeScroll onPostJob={openDialog} />
      <Login close={closeDialog} open={open}  />
    </Layout>
  )
}

export default Post;