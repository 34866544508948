import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import FirebaseApp from "./firebaseApp";
import { getAuth } from "firebase/auth";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import updateJob from "./updateJob";
import Job from "../Entities/Job";
import { nanoid } from "nanoid";

const uploadImage = (contentType: string, file: any, imageType: string, dispatch: Function, job: Job) => {
  const storage = getStorage(FirebaseApp);
  const auth = getAuth(FirebaseApp);
  const db = getFirestore(FirebaseApp);
  const user = auth.currentUser;
  // Create the file metadata
  /** @type {any} */
  const metadata = {
    contentType
  };

  if (!user) {
    dispatch({ type: 'UPLOAD_IMAGE_FAIL', error: "User not logged in"});
    return;
  }

  // Upload file and metadata to the object 'images/mountains.jpg'
  const storageRef = ref(storage, `images/${user.uid}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file, metadata);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on('state_changed',
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      dispatch({ type: 'UPLOAD_IMAGE_PROGRESS', progress: progress});
      switch (snapshot.state) {
        case 'paused':
          break;
        case 'running':
          break;
      }
    }, 
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          dispatch({ type: 'UPLOAD_IMAGE_FAIL', error: "User does not have perms"});
          break;
        case 'storage/canceled':
          dispatch({ type: 'UPLOAD_IMAGE_FAIL', error: "User cancelled"});
          break;

        // ...

        case 'storage/unknown':
          dispatch({ type: 'UPLOAD_IMAGE_FAIL', error: "Generic Storage Error"});
          break;
      }
    }, 
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        dispatch({ type: 'UPLOAD_IMAGE_SUCCESS'});
        const userImage = {
          userId: user.uid,
          imageType: imageType,
          imageUrl: downloadURL
        }
        const imageDoc = doc(db, 'userImages', nanoid());
        setDoc(imageDoc, userImage);
        job.logoImageUrl = downloadURL;
        dispatch({ type: 'UPDATE_JOB', job: job});
        updateJob(job);
      });
    }
  );
}

export default uploadImage;