import { StateContext, DispatchContext } from "../../utils/providers";
import { useContext, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import updateJob from '../../utils/updateJob';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { nanoid } from "nanoid";

const WizardTitle = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [open, setOpen] = useState(false); 

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  
  const titleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const jobTitle = event.target.value;
    let job = state.createJob.job;
    let jobUrl = jobTitle.split(' ').reduce((prev, cur) => prev ? prev + '-' + cur : cur, '');
    jobUrl += '-' + nanoid();
    job.jobTitle = jobTitle;
    job.jobUrl = jobUrl;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
    setOpen(true);
  };

  
  const companyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const company = event.target.value;
    let job = state.createJob.job;
    job.company = company;
    dispatch({ type: 'UPDATE_JOB', job: job});
    updateJob(job);
    setOpen(true);
  };
  
  return (
    <Box>
      <TextField
        fullWidth={true}
        id="outlined-basic"
        label="Job Title"
        variant="outlined"
        onChange={titleChange}
        value={state.createJob.job.jobTitle || ''}
        required />
      <TextField
          fullWidth={true}
          onChange={companyChange}
          id="outlined-basic"
          label="Company"
          variant="outlined"
          required
          value={state.createJob.job.company || ''}
          />
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          Saving Job
        </MuiAlert>
      </Snackbar>
    </Box>
  )
};

export default WizardTitle;